import { Avatar, Box, Card, CircularProgress, Typography } from '@mui/joy';
import React from 'react';
import { CiUser } from 'react-icons/ci';
import { SiOpenai } from 'react-icons/si';
import Random from '../../utils/Random';

export default function ChatDescription({
  algorithm,
  responseDelay = 1000,
}: {
  algorithm: string;
  responseDelay?: number;
}) {
  const [response, setResponse] = React.useState<JSX.Element>();

  React.useEffect(() => {
    setResponse(undefined);
    const timeout = setTimeout(() => {
      setResponse(Random[algorithm as keyof typeof Random].description);
    }, responseDelay);
    return () => clearTimeout(timeout);
  }, [algorithm, responseDelay]);


  return (
    <Card variant='outlined' sx={{
      width: '100%',
      p: 2,
      gap: 2,
    }}>
      <Typography level="h6">
        Description
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}>
          <Avatar color='primary' variant="solid">
            <CiUser size={20} />
          </Avatar>
          <Card variant='soft' sx={{
            p: 2,
          }}>
            Describe the {Random[algorithm as keyof typeof Random].name} distribution.
          </Card>

        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}>
          <Avatar color='success' variant="solid">
            {response ? (
              <SiOpenai size={20} />
            ) : (
              <CircularProgress>
                <SiOpenai size={20} />
              </CircularProgress>
            )}
          </Avatar>
          {response && (
            <Card variant='soft' sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2,
              gap: 2,
            }}>
              {response}
              <Typography level="body3">
                This description was generated by OpenAI's GPT-3 language model.
                {` `}
                <a href="https://openai.com/blog/gpt-3-apps/" target="_blank" rel="noreferrer">Learn more</a>
              </Typography>
            </Card>
          )}
        </Box>
      </Box>
    </Card>
  );
}